/* eslint-disable import/no-anonymous-default-export */
export default {
  // Login Page

  Login: "Login",
  Login_line: "Welcome back! Log in and let’s get started!",
  email_user: "Enter your email or username",
  email_user_alert: "Email or username is required",
  password: "Enter password",
  password_alert: "Password is required",
  forget: "Forgot Password?",
  or: 'Or',
  no_acc: "Don't have an account?",
  register: "Register",
  merchant: "Are you a merchant?",
  reg_merchant: "Register as a merchant",
  guest: "Continue as a ",
  guest_1: 'Guest',
  login_popup: "login sucessfully",


  //Agreement

  agree_check: "I accept the general terms & conditions of business, data protection Regulation and the code of conduction",
  i_accpt:
    "I accept the general ",
  i_accpt1:
    "terms & conditions",
  i_accpt2:
    "of business",
  i_accpt3:
    "data protection Regulation",
  i_accpt4:
    "and the",
  i_accpt5:
    "code of conduction",
  accept: "Accept",
  phone_popup1: "Phone already exists",
  phone_pupup2: "Please add phone number",
  phone_pupup3: "Invalid phone number!",
  //Otp
  otp: "OTP sent successfully",
  verify_code: "Enter Verification Code",
  code_line: "Your code was sent to",
  resend_code: "Resend code",
  no_code: "Didn't get a code?",
  // continue: "Continue",

  //Register
  code_match_alert: "Code matched successfully",
  reg_as_user: "Register as User",
  fname: "First Name",
  fname_alert: "First Name is required",
  lname: "Last Name",
  username: "Username",
  email: "Enter your email",
  email_alert: "Email is required",
  email_alert2: "Enter a valid email",
  password_alert2: "Password must be at least 8 characters long",
  conf_password: "Confirm password",
  conf_password_alert: "Confirm Password is required",
  conf_password_alert2: "Passwords must match",
  submit: "Sumbit",
  reg_alert: "User registered successfully",
  user_alert:"Username is required",
  user_alert2:"Username must not contain space",
  


  //Join Now
  join: "Join Now",
  skip: "Skip Now",
  essential: "Essential",
  credits: "Credits",
  ess_line1: "Perfect for Beginners",
  ess_line2: "Ideal for first-time users",
  ess_line3: "Great for occasional use.",
  ess_line4: "Try without long-term obligations.",
  most_popular: "Most Popular",
  pro: "Pro",
  pro_line1: "Three times the credits of Essential.",
  pro_line2: "Ideal for weekly or monthly use.",
  pro_line3: "More credits for greater flexibility.",
  pro_line4: "Try without long-term obligations.",
  elite: "Elite",
  eli_line1: "Perfect for intensive use.",
  eli_line2: "Highest credits for the best price.",
  eli_line3: "Best value with the lowest cost per credit.",

  //Merchant register
  apply_merchant: "Apply as Merchant",
  merchant_name: "Merchant Name",
  merchant_name_alert: "Merchant Name is required",
  merch_line: "*Must be Identical to the business registration. cannot be changed.",
  keyword: "Keyword",
  email_popup: "email sent successfully",
  email_popup1: "email already exists",
  email_alert3: "email already exists",
  merchant_popup1: "Merchant Verified Sucessfully",


  //welcome Screen
  welcome: "Welcome to Teals!",
  welcome_line: "Your account has been verified with your email address.",
  goto_login: "Go to Login",


  // forgot password screen
  forget_line: "In this step, please log in with the master password sent to your email and then change this in your profile",
  forget_popup: "Email not exists",
  master_popup: "Master code sent sucessfully",
  email_sent: "Email has been sent.",
  email_sent_line: "Email has been sent with new master password to login.",

  //Change password Screen
  change_pass: "Change Password",
  new_pass: "Enter New Password",
  change_pass_note: "Note: Access is restricted until your password is updated. Please proceed with updating it first.",
  update_pass: "Update Password",
  update_pass_popup: "Password updated sucessfully",

  //Home Screen
  top_offer: "Top offers",
  Categories: "Categories",
  cat1: "Retail",
  cat2: "Food & Drink",
  cat3: "Beauty & Wellness",
  cat4: "Services",
  cat5: "Leisure & Events",
  cat6: "Sports & OutDoor",
  cat7: "Pets",
  cat8: "Photoshoot",
  cat9: "Automotive & Motorcycle",
  cat10: "Home & Garden",
  popular_deals: "Popular Deals",
  see_all: "See All",
  top_merchant: "Top Merchant",
  discover_deals: "Discover deals near you",
  teal_work: "How Teals Works",
  teals_app: "Teals App",
  get_app: "Get App",
  download_teals: "Download Teals App",
  dealcat1: "Electronics",
  dealcat2: "Gaming",
  dealcat3: "Food & Household",
  dealcat4: "Fashion & Accessories",
  dealcat5: "Beauty & Health",
  dealcat6: "Family & Kids",
  dealcat7: "Home & Living",
  dealcat8: "Garden & DIY",
  dealcat9: "Cars & Motorcycles",
  dealcat10: "Culture & Leisure",
  dealcat11: "Sports & Outdoors",
  dealcat12: "Phone & Internet Contracts",
  dealcat13: "Insurance & Finance",
  dealcat14: "Services & Contracts",
  dealcat15: "Holidays & Travel",

  legal_info: "Legal Information",
  info1: "Data Protection",
  info2: "TOC",
  info3: "Cookies",
  info4: "Right of withdrawal",

  imprint: "Imprint",
  imprint1: "Legal notice",
  imprint2: "Company Info",

  tutorial: "Tutorial",
  tut1: "Getting Started",
  tut2: "FAQ",

  supp_edu: "Support & Education",
  supp1: "Help & Support",
  supp2: "Trust & Security",
  supp3: "Price & Fees",
  supp4: "Cancel a coupon purchase",

  community: "Community",
  com1: "Affiliate",
  com2: "Invite a Friend",
  com3: "Rules & Conditions",
  com4: "Code of conduct",

  buss_sol: "Business Solution",
  bus1: " How it works",
  bus2: "Contact Sales Team",

  select_city: "Top merchants in",
  choose_city: "Choose your City",
  no_merchants_in_city: "There are no dealers in this location yet. Just recommend Teals!",
  search_city: "Search City",

  //Create Deal Page
  create_deal: "Create a New Deal",
  upload_photo: "Upload Photo",
  upload_line: "Add clear photos of your product",
  title: "Title",
  title_holder: "Enter the title of the deal",
  deal_link: "Deal Link",
  description: "Description",
  desc_holder: "write details about Description",
  Sdate: "Start Date & Time",
  Edate: 'End Date & Time',
  Oprice: "Orignal Price",
  Dprice: "Deal Price",
  Dinclude: "Delivery Included?",
  yes: "yes",
  no: "no",
  Dcost: "Delivery Cost",
  code: "Code",
  code_holder: "Enter Code",
  deal_popup: "Deal created Sucessfully",


  // Added screen
  added: "Added",
  Dcreated: "Deal Created Successfully!",
  Dtext: "Your",
  Dtext2: " OFF on ",
  Dtext3: "is now live and will run from.",
  view_deal: "View Deal",

  //Deal Page
  Ddetails: "Deal Details",
  claim_deal: 'Claim Deal',
  Ava_delivery: "Available Delivery",
  delivery_incl: "incl. delivery",
  save: "Save",
  comment: "Comment",
  comments: "Comments",
  share: "Share",
  rec_deal: "Recommended Deals",
  no_comment: "No Comment Found",
  add_comment: "Add Comment",
  // added:"Added",


  //Market Screen
  market: "Market",
  search: "Search",
  map_view: "Map View",
  listing: "Listing",
  map_error_1:"Location is not available. Please ensure location permissions are enabled.",
  map_error_2:"You denied the location request. The map will be loaded without location information.",
  map_error_3:"Location information is unavailable.",
  map_error_4:"The request to get user location timed out.",
  map_error_5:"An unknown error occurred.",
  map_error_6:"Geolocation is not supported by this browser.",


  //sorting
  sort: "Sorting Market",
  a_z: "A to Z",
  z_a: "Z to A",
  sort3: "Near to far Distance",
  sort4: "Far to Near Distance",
  sort5: "Newest to Oldest",
  sort6: "Oldest to Newest",


  //Choose Category
  choose_cat: "Choose Category",


  //Deals screen
  deals: "Deals",
  exp_deal: "Explore All Deals",

  // Favorite screen
  fav: "Favorite",
  // deals:"Deals",
  coupons: "Coupons",
  coupons_sold: "Coupons sold",
  no_deal: "No Deal Found",
  no_coupon: "No Coupon Found",


  //Coupons Screen
  open: "Valid",
  radeemed: "Redeemed",
  no_radeemed: "No Redeemed Found",
  sold: "Sold",
  remaining: "Remaining",



  //Profile Screen
  profile: "Profile",
  faqs: "FAQs",
  likes: "Likes",
  buy_credits: "Buy Credits",
  // credits:"Credits",
  my_account: "My Account",
  securty: "Security",
  noti: "Notification",
  Tmessages: "Messages",
  logout: "Logout",


  //Account screen
  account: "Account",
  up_img: "Upload Image",
  min: "Min",
  update: "Update",
  del_succ: "Image removed successfully",
  // username:"Username",
  // address: "Address",
  edit: "Edit",
  close_acc: "Close Account",
  account_popup: "Data sucessfully updated",

  // Update User Screen
  up_user: "Update User",
  pers_info: "Personal Information",
  // save:"Save",

  //Address screeen
  // street: "Street",
  enter_St: "Enter Street",
  phone_no: "Phone Number",
  // country: "Country",
  ent_Country: "Enter Country",
  post_code: "Postal Code",
  ent_post_code: "Enter Postal Code",
  Tax_no: "Tax Number",
  ent_tax_no: "Enter Tax Number",

  // Close account
  what: "What",
  cose_text: "Do you really want to go?",
  stay: "Stay",
  confrim: "Confirm",
  convince: "Stay with us and enjoy exclusive discounts and free access. We have great offers just for you!",

  //Security
  low_sec: "Security",
  FA: "2FA Security",
  set_pass: "Set your account password",
  pass_text: "once you have reset your password all withdrawal will be temporarily suspended for 24 hrs",
  old_pass: "Old Password",
  new_pass2: "New Password",
  conf_new_pass: "Confirm New Password",
  save_ch: "Save Changes",
  pass_popup: "Password updated sucessfully",

  //connect Phone
  con_phone: "Connect your phone",
  con_phone_text: "Verification coe will be sent to your phone",
  get_code: "Verification coe will be sent to your phone",


  //Notifications

  mark_camp: "Marketing campaigns",
  push_noti: "Push Notification",
  // sound_alert: "Sound Alert",


  // Messages
  msg_text: "Select a message",
  msg_txt2: "Choose from your existing conversations, start a new one, or just keep swimming.",

  //logout
  logout_popup: "Logout Sucessfully",

  // Coupon Statistics

  coup_stats: "Coupon Statistics",
  overview: "OVERVIEW",
  total_coup_sold: "Total Coupons Sold",
  revenue: "Revenue Generated",
  act_coupon: "Active Coupons",
  exp_coupon: "Expired Coupons",
  pop_coupon: "MOST POPULAR COUPON",
  no_pop_coupon: "No Popular coupon found",
  le_pop_coupon: "LEAST POPULAR COUPON",
  no_le_pop_coupon: "No Least coupon found",


  //Marchent profile
  views: "Views",
  click: "Click",
  go_land: "Go to Landing Page",
  super: "SUPER",
  strong: "STRONG",
  wow: "WOW",
  perfect: "PERFECT",
  star: "STAR",
  kyb: "KYB",
  kyb_verify: "Verfication in Progress",
  info: "Information",
  report: "Report",
  send_reason: "Send Reason",
  Share: "Share",




  //Category screen
  cat_text: "Please Select your category of company and the use of the applicable agreed terms & conditions when selling your coupons!",


  //KYB screen

  ops: "Ooops!",
  kyb_text: "Just few steps in your profile and you’re ready to go...",
  req1: "Choose your category with TOC.",

  req3: "KYB Verification",


  //KYB verification 
  verify: "Verify",
  personal: "Personal",
  verification: "Verfication",
  dob: "Date of Birth",
  comp_type: "Company Type",
  small: "Small",
  large: "Large",
  perm_establish: "Permanent Establishment",
  open_hours: "Opening Hours",
  imprint_pub: "Imprint public premises",
  timezone: "TimeZone",
  weekly_hours: "Set your weekly hours",
  add_date: "Add date overrides",
  add_date_text: "Add dates when your availability changes from your weekly hours",
  select_date: "Select Date",
  up_bus_id: "Upload Business ID",
  up_id_text: "Please upload your business registration",
  take_photo: "Take A photo",
  sub: "Submitted",
  sub_text: "Your documents have been received and are currently queued for initial review. We appreciate your patience.",
  approx: "Approx 1 to 2 days",


  //kyb verification in progress
  verif_prog: "Verification in progress",
  verify_text: "Your documents have been in Progress.",
  sucess_verify: "Successfuly Verified",
  sucess_verify_text: "Congratulations! Your account has been verified successfully. You now have access to all benefits.",
  verify_fail: "Verification Failed",
  verify_fail_text: "Your documents have been Rejected. Please try again.",




  //marchant account'
  up_busi_cover: "Upload Business Cover Image",
  up_merchant: "Update Merchant",
  id_verify: "Identity Verification",
  not_verify: "Not Verified",
  subs: "subscription",
  ext_sub: "Extend subscription",
  no_business_popup: "You don't have Business to edit",

  //keyword
  up_key: "Update Keyword",

  //Identify Screen
  identify: "Identify",
  legal_name: "Legal Name",
  id_no: "Id Number",
  business: "Business",
  bus_info: "Business Information",
  comp_name: "Company Name",
  tax_id: "Tax id Number",
  kyb_verif: "KYB Verification",
  verif_req: "Verification Requirements",
  gov_id: "Government Issues ID",
  face_id: "Facial Identification",
  front_side: "Front Side",
  back_side: "Back Side",



  //Create Coupon screen
  cr_coupon: "Create a Coupon",
  // title_holder:"Enter the title of the coupon",
  inst_disc: "Instant Discount",
  max_purchase: "Max Purchases Per Day",
  quantity: "Quantity",
  org_price: "Orignal Price",
  discount: "Discount",
  vDate: "Valid Date",
  conditions: "Conditions",
  info_text: "Info Text",
  credits_hold: "Enter number of credits",
  add: "Add",
  coupon_popup: "Coupon created sucessfully",
  valid_until: "Valid until",

  //FAQs
  "faq_q1": "How does using deals work?",
  "faq_a1": "If you find a deal you're interested in, simply click on “Go to Deal.” You'll be taken directly to the website where you can purchase or redeem that deal.",
  "faq_q2": "Which payment methods are accepted?",
  "faq_a2": "Which payment options are available depends on the specific deal provider. In most cases, credit and debit cards like Visa, MasterCard, and American Express are accepted, and often PayPal or Apple Pay as well.",
  "faq_q3": "Are there any restrictions on the deals?",
  "faq_a3": "Usually not. Some providers may have specific conditions, such as a limited number of deals or a certain validity period. It's always best to check the offer details.",
  "faq_q4": "How do I redeem a deal?",
  "faq_a4": "That depends on where you purchased it. In most cases, the provider will give you clear instructions on how to redeem the deal.",

  //Purchase Successfull
  purchase_sucess: "Purchase Successful",
  purchase_text: "Your credits has been purchased",
  purchase_success: "Voucher successfully reserved",
  coupon_purchase_success_text: "Have fun saving money",

  //Radmon missings
  checkout_pro: "Checkout Process",
  order_summ: "Order summary",
  coup_res: "Coupon Reservation",
  valid: "Valid",
  buy_sure: "Are you sure you want to buy this coupon",
  cancel: "By confirming, you agree that the execution of the reservation will begin before your cancellation period expires.",
  reserve_now: "Reserve Now",
  avail_redem: "Coupons Available to redeem",
  inactive_redeem: "Inactive Redeem Coupon",
  tap_redeem: "Tap to Redeem",
  no_credits: "Your credits are insufficient to buy this coupon.",
  confirm_redeem: "Redeem Coupon?",
  redeem_confirmation_text: "Are you sure you want to redeem this coupon now? This action cannot be undone.",

  //Coupon redeem screen
  redeem_coup: "Redeem Coupon",
  coup_red_Sucs: "Coupon Redeemed Successfully!",
  red_sucs_text: "You Redeemed Your Coupon at",
  prov_feedback: "Provide your feedback",
  
  //Rate Exp
  all_done:"All Done",
  rate_exp: "Rate Experience",
  exp_already: "You have already given the review.",
  rev_sub:"Review Submitted",
  rev_sub_suc:"Review Submitted Successfully",
  


  //// new
  no_Merchant: 'No Merchant Found',
  change_language: 'Change Language',
  go_to_deal: 'Go to Deal',
  see_more: 'See more',
  s_deal: 'Sorting Deals',
  h_low: 'Highest to Lowest Price',
  l_high: 'Lowest to Highest Price',
  email_: 'Email',
  password_: 'Password',
  phone_: 'Phone',
  connectPhone: 'Connect Phone',
  c_phone: 'Current Phone number',
  change_phone: 'Current Phone number',
  add_phone: 'Add Phone Number',
  getCode: 'Get Code',
  old_pass_inc: 'Old password is incorrect',
  pass_not: 'Passwords do not match.',
  verified: 'Verified',
  Nokeywordfound: 'No keyword found',
  Category: 'Category',
  merchantVerified: 'Merchant Verified',
  req2: "Upload a profile picture",
  all: 'All',
  validity: 'Validity',
  s_validity: 'Select Validity',
  y_id: 'Your Id',
  postion_4: 'Position all 4 corners of the front clearly in the frame.',
  Front: 'Front',
  Back: 'Back',
  no_cat_found: 'No category found',
  no_avail: 'No Coupon Available',
  no_avail_timing: 'No available timings',
  not_avail_: 'Not Available',
  openingTiming: 'Opening Timing',
  gen_affiliate_link: 'Generate Affiliate link',
  affiliate_link: 'Affiliate link',
  couponDetails: 'Coupon Details',
  max_10: 'Max 10 purchases per day',
  couponExpired: 'Coupon expired',
  expired: 'expired',
  you_can: 'You cannot buy this coupon for the next ',
  agreement: 'Agreement',
  sendCode: 'Send Code',
  goto_home: 'Go to Home',
  howToTealvid: 'Teals Explained: Saving Made Simple!',
  howToDetailvid: 'Learn in just a few minutes how to find and redeem the best deals with Teals.',
  howToTeal1: 'DEALS',
  howToDetail1: '"Register for free and discover the power of community. Like, share, save, and comment on the top deals selected by the community, for the community."',
  howToTeal2: 'COUPON MARKET',
  howToDetail2: '"Browse exclusive, unbeatable offers from local merchants. Select your location or explore the map on TEALS to be surprised by a world of possibilities."',
  howToTeal3: 'CREDITS',
  howToDetail3: '"Choose from three flexible credit packages and top up your account — with no contracts and no hidden fees. Full transparency, full value."',
  howToTeal4: 'RESERVE A COUPON',
  howToDetail4: `"Conveniently reserve coupons with your credit balance. Arrange an appointment with the merchant and mention your Teals coupon — it's that simple!"`,
  howToTeal5: 'REDEEM A COUPON',
  howToDetail5: `"Redeem your coupon on-site and activate your discount immediately. Enjoy top-quality products or services and pay less. Saving has never been easier!"`,
  howToTeal6: 'RATE A MERCHANT',
  howToDetail6: `"Your opinion is golden! Rate your merchant and share your experiences. Your feedback strengthens our network and fosters a trustworthy community."`,
  howToTeal7: 'JOIN THE COMMUNITY',
  howToDetail7: `"Don't miss out on deals! Download the Teals app today and dive into a world of exclusive offers. Be part of something big and save on every purchase."`,
  catDataDetail1: 'Details about data protection',
  catDataDetail2: 'Details about TOC',
  catDataDetail3: 'Details about cookies',
  catDataDetail4: 'Details about the right of withdrawal',
  catDataDetail5: 'Details about the legal notice',
  catDataDetail6: 'Details about the company',
  catDataDetail7: 'Details about getting started',
  catDataDetail8: 'Frequently asked questions',
  catDataDetail9: 'Details about help and support',
  catDataDetail10: 'Details about trust and security',
  catDataDetail11: 'Details about prices and fees',
  catDataDetail12: 'Details about cancelling a coupon purchase',
  catDataDetail13: 'Details about affiliate',
  catDataDetail14: 'Details about inviting a friend',
  catDataDetail15: 'Details about rules and conditions',
  catDataDetail16: 'Details about code of conduct',
  catDataDetail17: 'Details about how it works',
  catDataDetail18: 'Details about contacting the sales team',
  opening_hours: 'Opening hours',
  days: 'Days',
  months: 'months',
  and: 'and',
  // credits: 'Credits',
  messages: 'Messages',
  select_message: 'Select a message',
  choose_message: 'Choose from your existing conversations, start a new one, or just keep swimming',
  notification: 'Notification',
  notifications: 'Notifications',
  notifications_info: 'Manage your notifications and choose how you would like to be informed.',
  no_notifications: 'No Notifications',
  once_reset_password: 'once you have reset your password all withdrawal will be temporarily suspended for 24 hrs',
  marketing: 'Marketing campaigns',
  push_notification: 'Push Notifications',
  sound_alert: 'Sound Alert',
  security: 'Security',
  low_level_security: 'Security Level',
  lorem_ispum: 'Lorem ipsum dolor sit amet consectetur',
  address: 'Address',
  city: "City",
  street: 'Street',
  streetwithno: "Street & No.",
  phone_number: 'Phone Number',
  country: 'Country',
  postal_code: 'Postal Code',
  tax_number: 'Tax Number',
  continue: 'Continue',
  payment_method: 'Payment Method',
  apply_now: 'Apply Now',
  teal_messages: 'Teal Messages',
  type_message: 'Type a Message...',
  credit_card: 'Credit / Debit Card',
  paypal_card: 'Paypal Card',
  buy_now: 'Buy Now',
  // buy_credits: 'Buy Credits',

  // Error Messages
  please_login: 'Please login to perform this action.',
  login_here: 'Login here'

}