/* eslint-disable import/no-anonymous-default-export */
export default {
  // Login Seite
  Login: "Anmelden",
  Login_line: "Willkommen zurück! Melde dich an und lass uns loslegen!",
  email_user: "Gib deine E-Mail oder deinen Benutzernamen ein",
  email_user_alert: "E-Mail oder Benutzername ist erforderlich",
  password: "Passwort eingeben",
  password_alert: "Passwort ist erforderlich",
  forget: "Passwort vergessen?",
  or: 'Oder',
  no_acc: "Noch keinen Account?",
  register: "Registrieren",
  merchant: "Bist du ein Händler?",
  reg_merchant: "Als Händler registrieren",
  guest: "Fortfahren als",
  guest_1: 'Gast',
  login_popup: "Erfolgreich eingeloggt",

  // Vereinbarung
  agree_check: "Ich akzeptiere die allgemeinen Geschäftsbedingungen, die Datenschutzbestimmungen und den Verhaltenskodex",
  accept: "Akzeptieren",
  phone_popup1: "Telefonnummer existiert bereits",
  phone_pupup2: "Bitte Telefonnummer hinzufügen",
  phone_pupup3: "Ungültige Telefonnummer!",
  i_accpt:
    "Ich akzeptiere die allgemeinen ",
  i_accpt1:
    "Geschäftsbedingungen",
  i_accpt2:
    "des Unternehmens",
  i_accpt3:
    "Datenschutzbestimmungen",
  i_accpt4:
    "und den",
  i_accpt5:
    "Verhaltenskodex",
  // OTP
  otp: "OTP erfolgreich gesendet",
  verify_code: "Verifizierungscode eingeben",
  code_line: "Dein Code wurde gesendet an",
  resend_code: "Code erneut senden",
  no_code: "Keinen Code erhalten?",
  continue: "Weiter",

  // Registrierung
  code_match_alert: "Code erfolgreich abgeglichen",
  reg_as_user: "Als Benutzer registrieren",
  fname: "Vorname",
  fname_alert: "Vorname ist erforderlich",
  lname: "Nachname",
  username: "Benutzername",
  email: "E-Mail eingeben",
  email_alert: "E-Mail ist erforderlich",
  email_alert2: "Gültige E-Mail eingeben",
  password_alert2: "Das Passwort muss mindestens 8 Zeichen lang sein",
  conf_password: "Passwort bestätigen",
  conf_password_alert: "Passwortbestätigung ist erforderlich",
  conf_password_alert2: "Passwörter müssen übereinstimmen",
  submit: "Einreichen",
  reg_alert: "Benutzer erfolgreich registriert",
  user_alert:"Benutzername ist erforderlich",
  user_alert2:"Benutzername darf kein Leerzeichen enthalten",

  // Jetzt beitreten
  join: "Jetzt beitreten",
  skip: "Überspringen",
  essential: "Essentiell",
  credits: "Credits",
  ess_line1: "Starte günstig durch",
  ess_line2: "Perfekt für den ersten Einstieg",
  ess_line3: "Jederzeit flexibel erweitern",
  ess_line4: "Ausprobieren ohne langfristige Verpflichtungen.",
  most_popular: "Am beliebtesten",
  pro: "Pro",
  pro_line1: "Sichere dir mehr Credits",
  pro_line2: "Bestes Preis-Leistungs-Verhältnis",
  pro_line3: "Ideal für regelmäßige Nutzung",
  pro_line4: "Ausprobieren ohne langfristige Verpflichtungen.",
  elite: "Elite",
  eli_line1: "Hol dir das beste Angebot",
  eli_line2: "Perfekt für hochwertige Angebote",
  eli_line3: "Maximale Credits zum Top Preis",

  // Händler Registrierung
  apply_merchant: "Als Händler bewerben",
  merchant_name: "Händlername",
  merchant_name_alert: "Händlername ist erforderlich",
  merch_line: "*Muss mit der Unternehmensregistrierung identisch sein. Kann nicht geändert werden.",
  keyword: "Schlüsselwort",
  email_popup: "E-Mail erfolgreich gesendet",
  email_popup1: "E-Mail existiert bereits",
  email_alert3: "E-Mail existiert bereits",
  merchant_popup1: "Händler erfolgreich verifiziert",

  // Willkommensbildschirm
  welcome: "Willkommen bei Teals!",
  welcome_line: "Dein Konto wurde mit deiner E-Mail-Adresse verifiziert.",
  goto_login: "Zum Login gehen",

  // Passwort vergessen Bildschirm
  forget_line: "In diesem Schritt bitte mit dem Master-Passwort einloggen, das an deine E-Mail gesendet wurde, und es dann in deinem Profil ändern",
  forget_popup: "E-Mail existiert nicht",
  master_popup: "Master-Code erfolgreich gesendet",
  email_sent: "E-Mail wurde gesendet.",
  email_sent_line: "E-Mail mit neuem Master-Passwort wurde gesendet, um sich einzuloggen.",

  // Passwort ändern Bildschirm
  change_pass: "Passwort ändern",
  new_pass: "Neues Passwort eingeben",
  change_pass_note: "Hinweis: Der Zugang ist eingeschränkt, bis dein Passwort aktualisiert wurde. Bitte aktualisiere es zuerst.",
  update_pass: "Passwort aktualisieren",
  update_pass_popup: "Passwort erfolgreich aktualisiert",

  // Startbildschirm
  top_offer: "Top Angebote",
  Categories: "Kategorien",
  cat1: "Einzelhandel",
  cat2: "Essen & Trinken",
  cat3: "Beauty & Wellness",
  cat4: "Dienstleistungen",
  cat5: "Freizeit & Veranstaltungen",
  cat6: "Sport & Outdoor",
  cat7: "Haustiere",
  cat8: "Fotoshooting",
  cat9: "Autos & Motorräder",
  cat10: "Heim & Garten",
  popular_deals: "Beliebte Deals",
  see_all: "Alle anzeigen",
  top_merchant: "Top Händler",
  discover_deals: "Entdecke Angebote in deiner Nähe",
  teal_work: "So funktioniert's",
  teals_app: "Teals App",
  get_app: "App herunterladen",
  download_teals: "Teals App herunterladen",
  dealcat1: "Elektronik",
  dealcat2: "Spiele",
  dealcat3: "Lebensmittel & Haushalt",
  dealcat4: "Mode & Accessoires",
  dealcat5: "Beauty & Gesundheit",
  dealcat6: "Familie & Kinder",
  dealcat7: "Wohnen & Leben",
  dealcat8: "Garten & Heimwerken",
  dealcat9: "Autos & Motorräder",
  dealcat10: "Kultur & Freizeit",
  dealcat11: "Sport & Outdoor",
  dealcat12: "Telefon- & Internetverträge",
  dealcat13: "Versicherungen & Finanzen",
  dealcat14: "Dienstleistungen & Verträge",
  dealcat15: "Urlaub & Reisen",

  legal_info: "Rechtliche Informationen",
  info1: "Datenschutz",
  info2: "AGB",
  info3: "Cookies",
  info4: "Widerrufsrecht",

  imprint: "Impressum",
  imprint1: "Rechtlicher Hinweis",
  imprint2: "Unternehmensinfo",

  tutorial: "Tutorial",
  tut1: "Erste Schritte",
  tut2: "FAQ",

  supp_edu: "Support & Bildung",
  supp1: "Hilfe & Support",
  supp2: "Vertrauen & Sicherheit",
  supp3: "Preis & Gebühren",
  supp4: "Gutschein-Kauf stornieren",

  community: "Community",
  com1: "Affiliate",
  com2: "Einen Freund einladen",
  com3: "Regeln & Bedingungen",
  com4: "Verhaltenskodex",

  buss_sol: "Business Lösung",
  bus1: "Teals Media Solutions",
  bus2: "Vertriebsteam kontaktieren",

  select_city: "Top Händler in",
  choose_city: "deiner Stadt",
  no_merchants_in_city: "Noch keine Händler in diesem Ort vorhanden. Empfiehl Teals doch einfach weiter!",
  search_city: "Stadt suchen",

  // Neues Angebot erstellen Seite
  create_deal: "Ein neuer Deal erstellen",
  upload_photo: "Foto hochladen",
  upload_line: "Füge klare Fotos deines Produkts hinzu",
  title: "Titel",
  title_holder: "Gib den Titel des Deals ein",
  deal_link: "Angebotslink",
  description: "Beschreibung",
  desc_holder: "Schreibe Details zur Beschreibung",
  Sdate: "Startdatum & Uhrzeit",
  Edate: "Enddatum & Uhrzeit",
  Oprice: "Originalpreis",
  Dprice: "Angebotspreis",
  Dinclude: "Lieferung inbegriffen?",
  yes: "Ja",
  no: "Nein",
  Dcost: "Lieferkosten",
  code: "Code",
  code_holder: "Code eingeben",
  deal_popup: "Deal erfolgreich erstellt",

  // Hinzugefügt Bildschirm
  added: "Hinzugefügt",
  Dcreated: "Deal erfolgreich erstellt!",
  Dtext: "Dein",
  Dtext2: "Rabatt auf",
  Dtext3: "ist jetzt live und läuft bis.",
  view_deal: "Deal ansehen",

  // Angebotsseite
  Ddetails: "Dealdetails",
  claim_deal: 'Zum Deal',
  Ava_delivery: "Versandkosten",
  delivery_incl: "inkl. Versand",
  save: "Speichern",
  comment: "Kommentar",
  comments: "Kommentare",
  share: "Teilen",
  rec_deal: "Empfohlene Deals",
  no_comment: "Keine Kommentare gefunden",
  add_comment: "Kommentar hinzufügen",

  // added: "Hinzugefügt",

  // Marktplatz Bildschirm
  market: "Lokale Deals",
  search: "Suche",
  map_view: "Kartenansicht",
  listing: "Liste",
  map_error_1:"Standort ist nicht verfügbar. Bitte stellen Sie sicher, dass die Standortberechtigungen aktiviert sind.",
  map_error_2:"Sie haben die Standortanfrage abgelehnt. Die Karte wird ohne Standortinformationen geladen.",
  map_error_3:"Standortinformationen sind nicht verfügbar.",
  map_error_4:"Die Anfrage zur Standortbestimmung des Nutzers ist abgelaufen.",
  map_error_5:"Ein unbekannter Fehler ist aufgetreten.",
  map_error_6:"Geolokalisierung wird von diesem Browser nicht unterstützt.",

  // Sortierung
  sort: "Marktplatz sortieren",
  a_z: "A bis Z",
  z_a: "Z bis A",
  sort3: "Nah zu fern",
  sort4: "Fern zu nah",
  sort5: "Neueste zuerst",
  sort6: "Älteste zuerst",

  // Kategorie wählen
  choose_cat: "Kategorie wählen",

  // Angebote Bildschirm
  deals: "Deals",
  exp_deal: "Alle Deals erkunden",

  // Favoriten Bildschirm
  fav: "Favoriten",
  // deals: "Angebote",
  coupons: "Gutscheine",
  coupons_sold: "Gutscheine verkauft",
  no_deal: "Keine Deals gefunden",
  no_coupon: "Keine Gutscheine gefunden",

  // Gutscheine Bildschirm
  open: "Gültig",
  radeemed: "Eingelöst",
  no_radeemed: "Keine eingelösten Gutscheine gefunden",
  sold: "Verkauft",
  remaining: "übrig",

  // Profil Bildschirm
  profile: "Profil",
  faqs: "FAQs",
  likes: "Gefällt mir",
  buy_credits: "Credits kaufen",
  // credits: "Credits",
  my_account: "Mein Konto",
  securty: "Sicherheit",
  noti: "Benachrichtigungen",
  Tmessages: "Chat",
  logout: "Abmelden",

  // Konto Bildschirm
  account: "Konto",
  up_img: "Bild hochladen",
  min: "Min",
  update: "Aktualisieren",
  del_succ: "Bild erfolgreich gelöscht",
  // username: "Benutzername",
  address: "Adresse",
  city: "Stadt",
  streetwithno: "Straße & Haus-Nr.",
  edit: "Bearbeiten",
  close_acc: "Konto schließen",
  account_popup: "Daten erfolgreich aktualisiert",

  // Benutzer aktualisieren Bildschirm
  up_user: "Benutzer aktualisieren",
  pers_info: "Persönliche Informationen",
  // save: "Speichern",

  // Adressbildschirm
  street: "Straße",
  enter_St: "Straße eingeben",
  phone_no: "Telefonnummer",
  country: "Land",
  ent_Country: "Land eingeben",
  post_code: "Postleitzahl",
  ent_post_code: "Postleitzahl eingeben",
  Tax_no: "Steuernummer",
  ent_tax_no: "Steuernummer eingeben",

  // Konto schließen
  what: "Was",
  cose_text: "Möchtest du wirklich gehen?",
  stay: "Bleiben",
  confrim: "Bestätigen",
  convince: "Bleib bei uns und genieße exklusive Rabatte und kostenlose Nutzung. Wir haben großartige Angebote für dich!",

  // Sicherheit
  low_sec: "Sicherheit",
  FA: "2FA Sicherheit",
  set_pass: "Passwort für dein Konto festlegen",
  pass_text: "Hier kannst du dein Passwort ändern.",
  old_pass: "Altes Passwort",
  new_pass2: "Neues Passwort",
  conf_new_pass: "Neues Passwort bestätigen",
  save_ch: "Änderungen speichern",
  pass_popup: "Passwort erfolgreich aktualisiert",

  // Telefon verbinden
  con_phone: "Telefon verbinden",
  con_phone_text: "Verifizierungscode wird an dein Telefon gesendet",
  get_code: "Verifizierungscode wird an dein Telefon gesendet",

  // Benachrichtigungen
  mark_camp: "Marketing-Kampagnen",
  push_noti: "Push-Benachrichtigungen",
  sound_alert: "Tonbenachrichtigung",

  // Nachrichten
  msg_text: "Nachricht auswählen",
  msg_txt2: "Wähle aus deinen vorhandenen Unterhaltungen, starte eine neue oder schwimme einfach weiter.",

  // Abmelden
  logout_popup: "Erfolgreich abgemeldet",

  // Gutschein-Statistik
  coup_stats: "Gutschein-Statistiken",
  overview: "ÜBERSICHT",
  total_coup_sold: "Verkaufte Gutscheine insgesamt",
  revenue: "Generierte Einnahmen",
  act_coupon: "Aktive Gutscheine",
  exp_coupon: "Abgelaufene Gutscheine",
  pop_coupon: "BELIEBTESTER GUTSCHEIN",
  no_pop_coupon: "Kein beliebter Gutschein gefunden",
  le_pop_coupon: "WENIGST BELIEBTER GUTSCHEIN",
  no_le_pop_coupon: "Kein wenigst beliebter Gutschein gefunden",

  // Händlerprofil
  views: "Ansichten",
  click: "Klicks",
  go_land: "Zur Landing Page gehen",
  super: "SUPER",
  strong: "STARK",
  wow: "WOW",
  perfect: "PERFEKT",
  star: "STAR",
  kyb: "KYB",
  kyb_verify: "Verifizierung in Bearbeitung",
  info: "Information",
  report: "Melden",
  send_reason: "Grund senden",
  Share: "Teilen",

  // Kategorie Bildschirm
  cat_text: "Bitte wähle deine Firmenkategorie und die entsprechenden vereinbarten Geschäftsbedingungen beim Verkauf deiner Gutscheine aus!",

  // KYB Bildschirm
  ops: "Oh nein!",
  kyb_text: "Nur noch ein paar Schritte in deinem Profil und du bist bereit...",
  req1: "Wähle deine Kategorie mit AGB.",
  req3: "KYB Verifizierung",

  // KYB Verifizierung
  verify: "Verifizieren",
  personal: "Persönlich",
  verification: "Verifizierung",
  dob: "Geburtsdatum",
  comp_type: "Unternehmensart",
  small: "Klein",
  large: "Groß",
  perm_establish: "Dauerhafte Einrichtung",
  open_hours: "Öffnungszeiten",
  imprint_pub: "Impressum öffentliche Räumlichkeiten",
  timezone: "Zeitzone",
  weekly_hours: "Wöchentliche Arbeitszeiten festlegen",
  add_date: "Datum hinzufügen",
  add_date_text: "Füge Daten hinzu, an denen sich deine Verfügbarkeit von den wöchentlichen Arbeitszeiten ändert",
  select_date: "Datum auswählen",
  up_bus_id: "Geschäftsausweis hochladen",
  up_id_text: "Bitte lade deine Geschäftsanmeldung hoch",
  take_photo: "Foto machen",
  sub: "Eingereicht",
  sub_text: "Deine Dokumente wurden erhalten und stehen derzeit zur Überprüfung an. Wir schätzen deine Geduld.",
  approx: "Ca. 1 bis 2 Tage",

  // KYB Verifizierung in Bearbeitung
  verif_prog: "Verifizierung in Bearbeitung",
  verify_text: "Deine Dokumente sind in Bearbeitung.",
  sucess_verify: "Erfolgreich verifiziert",
  sucess_verify_text: "Herzlichen Glückwunsch! Dein Konto wurde erfolgreich verifiziert. Du hast nun Zugriff auf alle Vorteile.",
  verify_fail: "Verifizierung fehlgeschlagen",
  verify_fail_text: "Deine Dokumente wurden abgelehnt. Bitte versuche es erneut.",

  // Händlerkonto
  up_busi_cover: "Geschäfts-Coverbild hochladen",
  up_merchant: "Händler aktualisieren",
  id_verify: "Identitätsverifizierung",
  not_verify: "Nicht verifiziert",
  subs: "Abonnement",
  ext_sub: "Abonnement verlängern",
  no_business_popup: "Du hast kein Geschäft zum Bearbeiten",

  // Schlüsselwort
  up_key: "Schlüsselwort aktualisieren",

  // Identifikationsbildschirm
  identify: "Identifizieren",
  legal_name: "Rechtlicher Name",
  id_no: "Ausweisnummer",
  business: "Geschäft",
  bus_info: "Geschäftsinformationen",
  comp_name: "Firmenname",
  tax_id: "Steuer-ID",
  kyb_verif: "KYB Verifizierung",
  verif_req: "Verifizierungsanforderungen",
  gov_id: "Regierungs-ID",
  face_id: "Gesichtserkennung",
  front_side: "Vorderseite",
  back_side: "Rückseite",

  // Gutschein erstellen Bildschirm
  cr_coupon: "Gutschein erstellen",
  // title_holder: "Gib den Titel des Gutscheins ein",
  inst_disc: "Sofortrabatt",
  max_purchase: "Maximale Käufe pro Tag",
  quantity: "Menge",
  org_price: "Originalpreis",
  discount: "Rabatt",
  vDate: "Gültigkeitsdatum",
  conditions: "Weitere Informationen",
  info_text: "Infotext",
  credits_hold: "Gib die Anzahl der Credits ein",
  add: "Hinzufügen",
  coupon_popup: "Gutschein erfolgreich erstellt",
  valid_until: "Gültig bis",

  // FAQs
  "faq_q1": "Wie funktioniert das Nutzen von Deals?",
  "faq_a1": "Wenn du dich für einen Deal interessierst, klicke einfach auf „Zum Deal“. Du wirst dann direkt auf die entsprechende Seite weitergeleitet und kannst den Deal dort kaufen oder einlösen.",
  "faq_q2": "Welche Zahlungsmethoden werden akzeptiert?",
  "faq_a2": "Welche Zahlungsarten es gibt, hängt vom jeweiligen Deal-Anbieter ab. In der Regel werden Kredit- und Debitkarten wie Visa, MasterCard und American Express akzeptiert, oft auch PayPal oder Apple Pay.",
  "faq_q3": "Gibt es Einschränkungen bei den Deals?",
  "faq_a3": "Normalerweise nicht. Manche Anbieter haben jedoch eigene Bedingungen wie eine begrenzte Stückzahl oder Gültigkeitsdauer. Lies am besten immer die Angebotsdetails durch.",
  "faq_q4": "Wie löse ich einen Deal ein?",
  "faq_a4": "Das hängt davon ab, wo du den Deal gekauft hast. Meist erhältst du vom Anbieter eine genaue Anleitung, wie du den Deal einlösen kannst.",

  // Kauf erfolgreich
  purchase_sucess: "Kauf erfolgreich",
  purchase_text: "Deine Credits wurden gekauft",
  purchase_success: "Gutschein erfolgreich reserviert",
  coupon_purchase_success_text: "Viel Spaß beim Geld sparen",

  // Verschiedene fehlende Punkte
  checkout_pro: "Checkout Prozess",
  order_summ: "Bestellübersicht",
  coup_res: "Gutscheinreservierung",
  valid: "Gültig",
  buy_sure: "Bist du sicher, dass du diesen Gutschein reservieren möchtest?",
  cancel: "Mit der Bestätigung erklärst du dich damit einverstanden, dass die Ausführung der Reservierung vor Ablauf deiner Widerrufsfrist beginnt.",
  reserve_now: "Jetzt reservieren",
  avail_redem: "Verfügbarer Gutschein zum Einlösen",
  inactive_redeem: "Inaktiver Gutschein einlösen",
  tap_redeem: "Zum Einlösen tippen",
  no_credits: "Deine Credits reichen nicht aus, um diesen Gutschein zu kaufen.",
  confirm_redeem: "Gutschein einlösen?",
  redeem_confirmation_text: "Bist du sicher, dass du diesen Gutschein jetzt einlösen möchtest? Diese Aktion kann nicht rückgängig gemacht werden.",


  // Gutschein einlösen Bildschirm
  redeem_coup: "Gutschein einlösen",
  coup_red_Sucs: "Gutschein erfolgreich eingelöst!",
  red_sucs_text: "Du hast deinen Gutschein eingelöst am",
  prov_feedback: "Gib dein Feedback",

  // Erfahrung bewerten
  all_done:"Fertig",
  rate_exp: "Erfahrung bewerten",
  exp_already: "Du hast bereits eine Bewertung abgegeben.",
  rev_sub:"Bewertung abgegeben",
  rev_sub_suc:"Bewertung erfolgreich abgegeben",


  //// neu
  no_Merchant: 'Kein Händler gefunden',
  change_language: 'Sprache ändern',
  go_to_deal: 'Zum Deal',
  see_more: 'Mehr sehen',
  s_deal: 'Deals sortieren',
  h_low: 'Höchster zu niedrigster Preis',
  l_high: 'Niedrigster zu höchster Preis',
  email_: 'E-Mail',
  password_: 'Passwort',
  phone_: 'Telefon',
  connectPhone: 'Telefon verbinden',
  c_phone: 'Aktuelle Telefonnummer',
  change_phone: 'Telefonnummer ändern',
  add_phone: 'Telefonnummer hinzufügen',
  getCode: 'Code erhalten',
  old_pass_inc: 'Altes Passwort ist falsch',
  pass_not: 'Passwörter stimmen nicht überein.',
  verified: 'Verifiziert',
  Nokeywordfound: 'Kein Schlüsselwort gefunden',
  Category: 'Kategorie',
  merchantVerified: 'Händler verifiziert',
  req2: "Profilbild hochladen",
  all: 'Alle',
  validity: 'Gültigkeit',
  s_validity: 'Gültigkeit auswählen',
  y_id: 'Ihre ID',
  postion_4: 'Positionieren Sie alle 4 Ecken der Vorderseite klar im Rahmen.',
  Front: 'Vorderseite',
  Back: 'Rückseite',
  no_cat_found: 'Keine Kategorie gefunden',
  no_avail: 'Kein Gutschein verfügbar',
  no_avail_timing: 'Keine verfügbaren Zeiten',
  not_avail_: 'Nicht verfügbar',
  openingTiming: 'Öffnungszeiten',
  gen_affiliate_link: 'Affiliate-Link generieren',
  affiliate_link: 'Affiliate-Link',
  couponDetails: 'Gutscheindetails',
  max_10: 'Maximal 10 Käufe pro Tag',
  couponExpired: 'Gutschein abgelaufen',
  expired: 'Abgelaufen',
  you_can: 'Du kannst diesen Gutschein nicht kaufen für die nächsten',
  agreement: 'Vereinbarung',
  sendCode: 'Code senden',
  goto_home: 'Zur Startseite',
  howToTealvid: 'So geht Teals: Einfach erklärt',
  howToDetailvid: 'Entdecke in unserem kurzen Video, wie du die besten Deals mit Teals findest und einlöst.',
  howToTeal1: 'DEALS',
  howToDetail1: '"Registrier dich kostenlos und werde Teil der Community. Teile, like, speicher und kommentiere die besten Deals aus dem Internet. Von der Community für die Community"',
  howToTeal2: 'COUPONMARKT',
  howToDetail2: '"Hier findest du exclusive und unschlagbare Angebote von lokalen Händlern. Wähle deinen Standort oder scrolle auf der Karte, und lasse dich von der Angebotsvielfalt auf TEALS überraschen."',
  howToTeal3: 'CREDITS',
  howToDetail3: '"Wähle ganz einfach aus einem der drei Creditpakete und lade dein Guthaben auf. Keine Vertragsbindung, keine versteckten Kosten."',
  howToTeal4: 'COUPON RESERVIEREN',
  howToDetail4: `"Reserviere deinen Coupon ganz bequem mit deinem aufgeladenen Creditguthaben. Vereinbare anschließend einen Termin beim Händler und nenne deinen Tealscoupon."`,
  howToTeal5: 'COUPON EINLÖSEN',
  howToDetail5: `"Löse den Coupon vor Ort ein und aktiviere deinen Rabatt. Dienstleistung erhalten, genießen und Sparen. Zahle im Anschluss ganz einfach den reduzierten Betrag beim Händler vor Ort."`,
  howToTeal6: 'HÄNDLER BEWERTEN',
  howToDetail6: `"Deine Meinung zählt! Teile mit, wie zufrieden du mit deinem Händler bist. Dies hilft der Community, eine „echte“ und vertrauensvolle Umgebung zu schaffen."`,
  howToTeal7: 'WERDE TEIL DER COMMUNITY ',
  howToDetail7: `"Lade dir noch heute die Teals APP herunter und spare durch die unzählige Angebotsvielfalt auf TEALS."`,
  catDataDetail1: 'Details zum Datenschutz',
  catDataDetail2: 'Details zu den AGB',
  catDataDetail3: 'Details zu Cookies',
  catDataDetail4: 'Details zum Widerrufsrecht',
  catDataDetail5: 'Details zum Impressum',
  catDataDetail6: 'Details zum Unternehmen',
  catDataDetail7: 'Details zum Einstieg',
  catDataDetail8: 'Häufig gestellte Fragen',
  catDataDetail9: 'Details zu Hilfe und Support',
  catDataDetail10: 'Details zu Vertrauen und Sicherheit',
  catDataDetail11: 'Details zu Preisen und Gebühren',
  catDataDetail12: 'Details zur Stornierung eines Gutschein-Kaufs',
  catDataDetail13: 'Details zu Affiliate',
  catDataDetail14: 'Details zur Einladung eines Freundes',
  catDataDetail15: 'Details zu Regeln und Bedingungen',
  catDataDetail16: 'Details zum Verhaltenskodex',
  catDataDetail17: 'Details zur Funktionsweise',
  catDataDetail18: 'Details zum Kontaktieren des Verkaufsteams',
  opening_hours: 'Öffnungszeiten',
  days: 'Tage',
  months: 'Monate',
  and: 'und',
  // credits: 'Credits',
  messages: 'Nachrichten',
  select_message: 'Wähle eine Nachricht aus',
  choose_message: 'Wähle aus deinen bestehenden Unterhaltungen, starte eine neue oder mach einfach weiter',
  notification: 'Benachrichtigung',
  notifications: 'Benachrichtigungen',
  notifications_info: 'Verwalte deine Benachrichtigungen und wähle, wie du informiert werden möchtest.',
  no_notifications: 'Keine Benachrichtigungen',
  once_reset_password: 'Sobald du dein Passwort zurückgesetzt hast, werden alle Abhebungen vorübergehend für 24 Stunden ausgesetzt.',
  marketing: 'Marketingkampagnen',
  push_notification: 'Push-Benachrichtigungen',
  // sound_alert: 'Sound Alert',
  security: 'Sicherheit',
  low_level_security: 'Sicherheitsniveau',
  lorem_ispum: 'Cras in viverra a sit dignissim',
  // address: 'Address',
  // street: 'Street',
  phone_number: 'Telefonnummer',
  // country: 'Country',
  postal_code: 'Postleitzahl',
  tax_number: 'Steuernummer',
  type_message: 'Eine Nachricht eingeben...',
  // continue: 'Continue',
  payment_method: 'Zahlungsmethode',
  apply_now: 'Jetzt kaufen',
  credit_card: 'Kreditkarte (Visa, Amex, Mastercard, etc.)',
  paypal_card: 'PayPal',
  // buy_credits: 'Buy Credits',
  teal_messages: 'Chat',
  buy_now: 'Jetzt kaufen',
  please_login: 'Bitte logge dich ein, um diese Aktion durchzuführen.',
  login_here: 'Hier einloggen'

}